import React from 'react';
import { useAuthentication } from '../util/authentication';
import {MasterLayout} from './MasterLayout';
import { BlankLayout } from './BlankLayout';

export const AutoSwitchLayout : React.FunctionComponent<any> = ({ children }) => {
  const { isAuthenticated } = useAuthentication();
  const Layout = isAuthenticated ? MasterLayout : BlankLayout;

  return <Layout>{children}</Layout>;
}
