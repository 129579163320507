import { AnimationClassNames, getTheme, mergeStyleSets } from "@fluentui/style-utilities";
import React,{useState} from "react";
import { getStyles } from "./Nav.styles";
//import { NavBase } from "./NavBase.txt";
import { NavLink } from "./NavLink";

export const Nav : React.FunctionComponent<any> = (props)=> {
  const [isLinkExpandStateChanged,setLinkExpandStateChanged] = useState(false);
  const [selectedKey,setSelectedKey] = useState(props.initialSelectedKey || props.selectedKey);

  let _hasAtleastOneHiddenLink = false;

  const getPreferredSelectedKey = () => {
    let selectedKey = "";
    // if caller passes in selectedKey, use it as first choice or use current state.selectedKey
    if (props.selectedKey) {
      selectedKey = props.selectedKey;
    } else if (selectedKey) {
      selectedKey = selectedKey;
    }
    return selectedKey;
  }

  /* given a link, find if one of the child is selected */
  const isChildLinkSelected = (link) => {
    const selectedKey = getPreferredSelectedKey();
    if (!selectedKey || !link?.links?.length) {
      return false;
    }
    return link.links.some(childLink => {
      return !!childLink && childLink.key === selectedKey;
    });
  }

  // given a link and an optional includeChildren parameter, find if the link or any of the children is selected
  const isLinkSelected = (link, includeChildren) =>{
    const selectedKey = getPreferredSelectedKey();
    if (!selectedKey || !link) {
      return false;
    }
    // check if the link or any of the child link is selected
    return (
      link.key === selectedKey ||
      (includeChildren && isChildLinkSelected(link))
    );
  }

  const getLinkText = (link, showMore) => {
    if (!link) {
      return undefined;
    }
    if (link.isShowMoreLink && !showMore && link.alternateText) {
      // if the link is show more/less link, based on the showMore state; return "Show more" localized text
      return link.alternateText;
    }
    return link.name;
  }

  // find if atleast one child link is visible using isHidden property
  // showMore flag will overwrite isHidden property
  const hasAtleastOneVisibleLink = (links, showMore) =>{
    return links?.length && (
      links.some(link => {
        return !link.isHidden;
      }) || !!showMore
    );
  }
  

  const _onLinkClicked = (link, ev) => {
    let nextState : any = {
      selectedKey: link.key
    };

    const hasChildren = link.links?.length;

    if (hasChildren) {
      // show child links
      link.isExpanded = !link.isExpanded;
      nextState.isLinkExpandStateChanged = true;
    } else if (link.onClick) {
      link.onClick(ev, link);
    }
    setLinkExpandStateChanged(nextState.isLinkExpandStateChanged);
    setSelectedKey(nextState.selectedKey);

    if (hasChildren || link.onClick) {
      // prevent further action if the link has children or onClick handler is defined
      ev.preventDefault();
    }

    ev.stopPropagation();
  }

  const _renderCompositeLink = (link, linkIndex, nestingLevel) => {
    const { onShowMoreLinkClicked, styles, showMore } = props;

    if (!link) {
      return null;
    }

    let rightIconName = undefined;
    let ariaProps = {};
    if (link.links && link.links.length > 0 && nestingLevel === 0) {
      // for the first level link, show chevron icon if there is a children
      rightIconName = link.isExpanded ? "ChevronUp" : "ChevronDown";
      ariaProps = { ariaExpanded: !!link.isExpanded };
    } else if (link.url && link.target === "_blank") {
      // for external links, show an icon
      rightIconName = "OpenInNewWindow";
    }

    // show nav icon for the first level only
    const leftIconName = nestingLevel === 0 ? link.icon : undefined;
    const isLnkSelected = isLinkSelected(link, false /* includeChildren */);
    const isChldLinkSelected = isChildLinkSelected(link);
    const hasChildren = link.links?.length;
    const isSelected =
      (isLnkSelected && !hasChildren) ||
      (isChldLinkSelected && !link.isExpanded);
    const classNames = mergeStyleSets(getStyles({...styles,isSelected, nestingLevel, isChldLinkSelected, theme:getTheme()}));

    const linkText = getLinkText(link, showMore);
    const onClickHandler =
      link.isShowMoreLink && onShowMoreLinkClicked
        ? onShowMoreLinkClicked
        : _onLinkClicked.bind(this, link);

    return (
      <NavLink
        id={link.key}
        content={linkText}
        href={link.url}
        target={link.target}
        onClick={onClickHandler}
        dataValue={link.key}
        ariaLabel={linkText}
        {...ariaProps}
        role="menu"
        rootClassName={classNames.navItemRoot}
        leftIconName={leftIconName}
        rightIconName={rightIconName}
        textClassName={classNames.navItemNameColumn}
        iconClassName={classNames.navItemIconColumn}
        barClassName={classNames.navItemBarMarker}
        focusedStyle={classNames.focusedStyle}
      />
    );
  }

  const _renderLink = (link, linkIndex, nestingLevel) =>{
    if (!link) {
      return null;
    }

    const linkText = getLinkText(link,props.showMore);

    return (
      <li key={link.key || linkIndex} title={linkText}>
        {_renderCompositeLink(link, linkIndex, nestingLevel)}
        {// show child links
        // 1. only for the first level and
        // 2. if the link is expanded
        nestingLevel === 0 && link.isExpanded ? (
          <div className={AnimationClassNames.slideDownIn20}>
            {_renderLinks(link.links, ++nestingLevel)}
          </div>
        ) : null}
      </li>
    );
  }

  const _renderLinks = (links, nestingLevel) => {
    if (!links?.length) {
      return null;
    }
    const { showMore } = props;
    return (
      <ul>
        {links.map((link, linkIndex) => {
          if (link.isHidden && !showMore) {
            // atleast one link is hidden
            _hasAtleastOneHiddenLink = true;

            // "Show more" overrides isHidden property
            return null;
          } else if (
            link.isShowMoreLink &&
            !_hasAtleastOneHiddenLink &&
            !showMore
          ) {
            // there is no hidden link, hide "Show more" link
            return null;
          } else {
            return _renderLink(link, linkIndex, nestingLevel);
          }
        })}
      </ul>
    );
  }

  const _renderGroup = (group, groupIndex) =>{
    const { styles } = props;

    if (!group?.links?.length) {
      return null;
    }

    const hasGroupName = !!group.name;

    const classNames = mergeStyleSets(getStyles({...styles,hasGroupName, theme:getTheme()}));
    // first group header is hidden by default, display group header for other groups only if there are visible links
    let isGroupHeaderVisible = false;
    if (groupIndex > 0) {
      isGroupHeaderVisible = hasAtleastOneVisibleLink(
        group.links,
        props.showMore
      );
    }

    return (
      <div key={groupIndex}>
        {isGroupHeaderVisible ? (
          <div className={classNames.navGroupSeparatorRoot}>
            <div className={classNames.navGroupSeparatorHrLine}>
              {group.name ? (
                <span className={classNames.navGroupSeparatorHeaderGroupName}>
                  {group.name}
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
        {_renderLinks(group.links, 0 /* nestingLevel */)}
      </div>
    );
  }


  if (!props.groups?.length) {
    return null;
  }
  // reset the flag
  // on render link, find if there is atleast one hidden link to display "Show more" link
  return (
    <>
      {props.groups.map((group, groupIndex) => {
        return _renderGroup(group, groupIndex);
      })}
    </>
  );


}
