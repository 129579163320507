import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloakConfig = Keycloak({
  url: 'https://auth.poorvika.com/auth',
  realm: 'poorvika',
  clientId: 'pim',
});

export default keycloakConfig;