import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from './RootReducer';
import  { createLogger }  from 'redux-logger';
import {isDev} from 'util/Constant';

let middleware = [thunk];
//https://github.com/ammoradi/cra-template-star-pwa/tree/master/template/src
if (isDev()) {
	console.log("Redux loaded in Dev Mode !!!");
	const reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default();
	const logger = createLogger({ collapsed: true });
	middleware = [...middleware, reduxImmutableStateInvariant, logger];
} else {
	middleware = [...middleware];
}

const store = createStore(
    RootReducer,
    applyMiddleware(...middleware)
);

export default store;
