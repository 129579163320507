import React,{useState} from 'react';
import { FocusZone, FocusZoneDirection } from '@fluentui/react';
import { Nav } from './Nav';
import { SlimNav } from './SlimNav';
import { getStyles } from './Nav.styles';
import { NavLink } from './NavLink';
import { getTheme, mergeStyleSets } from '@fluentui/react';

export const NavToggler : React.FunctionComponent<any> = (props)=> {
  const [isNavCollapsed,setNavCollapsed] = useState(localStorage.getItem('NavToggler.isNavCollapsed') === 'true' || window.innerWidth < 720);
  const [showMore,setShowMore] = useState(localStorage.getItem('NavToggler.showMore') === 'true');
  const { styles, groups } = props;

    if (!groups?.length) {
      return null;
    }
    const classNames = mergeStyleSets(getStyles({...styles,isCollapsed: isNavCollapsed,theme: getTheme()}));

    const toggleNavGroups = groups.filter(navGroup => navGroup?.groupType === 'ToggleGroup');
    const nonToggleNavGroups = groups.filter(navGroup => navGroup?.groupType !== 'ToggleGroup');

    const _onNavCollapseClicked = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      localStorage.setItem('NavToggler.isNavCollapsed', isNavCollapsed ? 'false' : 'true');
      setNavCollapsed(!isNavCollapsed);
    }
  
    const _renderExpandCollapseNavItem = (toggleNavGroups) => {
  
      const link = toggleNavGroups?.[0]?.links?.[0];
  
      if (!link) {
        // There is no toggle group with links defined
        return null;
      }
      const ariaLabel = isNavCollapsed ? link.name : link.alternateText;
  
      return (
        <>
          <NavLink
            id={link.key}
            href={link.url}
            onClick={_onNavCollapseClicked}
            ariaExpanded={!isNavCollapsed}
            dataValue={link.key}
            ariaLabel={ariaLabel}
            rootClassName={classNames.navToggler}
            leftIconName={link.icon}
            iconClassName={classNames.navItemIconColumn}
            barClassName={classNames.navItemBarMarker}
            focusedStyle={classNames.focusedStyle}
            role="menu"
            title={"Poorvika"}
          />
        </>
      );
    }
  
    const _onShowMoreLinkClicked = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      localStorage.setItem('NavToggler.showMore', showMore ? 'false' : 'true');
      setNavCollapsed(!showMore);
    }

    return (
      <div className={classNames.root}>
        <FocusZone direction={FocusZoneDirection.vertical}>
          <nav role="navigation">
            {_renderExpandCollapseNavItem(toggleNavGroups)}
            {isNavCollapsed ? (
              <SlimNav
                groups={nonToggleNavGroups}
                selectedKey={props.selectedKey}
                showMore={showMore}
                onShowMoreLinkClicked={_onShowMoreLinkClicked}
              />
            ) : (
              <Nav
                groups={nonToggleNavGroups}
                selectedKey={props.selectedKey}
                showMore={showMore}
                onShowMoreLinkClicked={_onShowMoreLinkClicked}
              />
            )}
          </nav>
        </FocusZone>
      </div>
    );
}
