import React from 'react';
import {  IContextualMenuProps,CommandBarButton, Stack, IDropdownOption, Dropdown, Label } from '@fluentui/react';
import { useAuthentication } from 'util/authentication';
import { AxiosHTTPClient } from 'util/AxiosInstance';
import { useHistory } from 'react-router-dom';
import { useConst } from '@fluentui/react-hooks';
import { useKeycloak } from '@react-keycloak/web';
import type { KeycloakInstance } from 'keycloak-js';
import { IFailureResponse, ISuccessResponse, ResponseType } from 'util/Constant';
import { ILocale, LocaleService } from 'service/locale/LocaleService';

export const UserMenu : React.FunctionComponent = () => {
  const {keycloak} = useKeycloak<KeycloakInstance>();
  const { principal,settings,resetLocale, logout } = useAuthentication();
  const axiosInstance = AxiosHTTPClient.getAxiosWithToken();
  const localeService : LocaleService  = new LocaleService(axiosInstance);
  const [localeOptions,setLocaleOptions]  = React.useState<Array<IDropdownOption>>([]);
  const [locale, setLocale] = React.useState<string>("");
  
  const logoutUser = () => {
    if(keycloak?.authenticated){
      keycloak.clearToken();
      keycloak.logout();
      logout();
    }else{
      logout();
    }
  }
  const history = useHistory();
  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: 'profile',
        text: 'Profile',
        iconProps: { iconName: 'PlayerSettings' },
        onClick: () => history.push('/profile')
      },
      {
        key: 'logout',
        text: 'Logout',
        iconProps: { iconName: 'SignOut' },
        onClick: logoutUser
      }
    ]
  }));

  React.useEffect(() => {
    console.log('Running effect once on UserMenu');  
    (async function loadContent() {
      try{
        let localeRes : ISuccessResponse | IFailureResponse = await localeService.list({isActive:true});
        if(localeRes.type==ResponseType.SUCCESS){
          var locales : Array<ILocale> = localeRes.msg;
          setLocaleOptions(locales.map((locale)=>({ key: locale.code, text: locale.name})));
          setLocale(settings?.default?.locale)
        }
      }catch(error){
        console.log(error);
      }
    })();
    return () => {
      console.log('Running clean-up of effect on unmount Usermenu')
    }
  },[]);


  return (
    <Stack horizontal horizontalAlign={"end"} tokens={{childrenGap:"20"}}>
        <Label>Locale</Label>
        <Dropdown
            placeholder="Select an Locale"
            ariaLabel="Locale"
            selectedKey={locale ? locale : undefined}
            onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption)=>{
              resetLocale(item.key.toString());
              setLocale(item.key.toString());
            }}
            options={localeOptions}
          />
        <CommandBarButton
          menuProps={menuProps}
          iconProps={{iconName:'UserOptional'}}>
          {principal?.displayName}
        </CommandBarButton>
    </Stack>
    
  );
}
