import { useEffect, useRef } from 'react';
import axios from 'axios';
import type { AxiosInstance} from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import type { KeycloakInstance } from 'keycloak-js';
import { useAuthentication } from './authentication';
import { BASE_API_URL, BASE_DOMAIN_URL } from './Constant';

export class AxiosHTTPClient {
  private static axiosClient = null;

  static getAxiosWithToken (){
      if(this.axiosClient == null){
        const {keycloak} = useKeycloak<KeycloakInstance>();
        const {principal,logout} = useAuthentication();
        this.axiosClient = axios.create({baseURL : BASE_API_URL});
        this.axiosClient.interceptors.request.use(config => new Promise((resolve, reject) => {
          try{
         if(principal?.authprovider==="KEYCLOAK")
            keycloak.updateToken(5).then(() => {
              config.headers.Authorization = 'Bearer ' + keycloak?.token
              resolve(config);
            }).catch(() => {
              logout();
              window.location.href = `${BASE_DOMAIN_URL}/login`;
            });
          else{
            config.headers.Authorization = 'PIM_TOKEN ' + principal?.token
            resolve(config);
          }
          }catch(ex){
            logout();
            window.location.href = `${BASE_DOMAIN_URL}/login`;
          }
        }));
        this.axiosClient.interceptors.response.use(function (response) {
          return response;
        }, function (error) {
          return Promise.reject(error);
        });
        return this.axiosClient;
      }else{
        return this.axiosClient;
      }
  }

  static resetAxiosToken () {
    this.axiosClient  =  null;
  }
}

/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
class Singleton {
  private static instance: Singleton;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() { }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): Singleton {
      if (!Singleton.instance) {
          Singleton.instance = new Singleton();
      }

      return Singleton.instance;
  }

  /**
   * Finally, any singleton should define some business logic, which can be
   * executed on its instance.
   */
  public someBusinessLogic() {
      // ...
  }
}


export const useAxiosWithToken = (baseURL: string) => {
  const axiosInstance = useRef<AxiosInstance>();
  const {keycloak} = useKeycloak<KeycloakInstance>();
  const { login } = useAuthentication();
  const kcToken = keycloak?.token ?? '';
  //console.log("Axios Token : ",kcToken);

  useEffect(() => {
    axiosInstance.current = axios.create({baseURL});
    axiosInstance.current.interceptors.request.use(config => new Promise((resolve, reject) => 
          keycloak.updateToken(5).then(() => {
            config.headers.Authorization = 'Bearer ' + keycloak?.token
            resolve(config)
          }).catch(() => {
            //login();
          })
    ))
  
    return () => {
      axiosInstance.current = undefined;
    };
  }, [baseURL, kcToken]);

  return axiosInstance;
};

export const useAxiosWithoutToken = (baseURL: string) => {
  let axiosInstance : AxiosInstance;
  useEffect(() => {
    axiosInstance = axios.create({baseURL});
    return () => {
      axiosInstance = undefined;
    };
  }, [baseURL]);

  return axiosInstance;
};