import React,{useState} from "react";
import { getStyles } from "./Nav.styles";
//import { NavBase } from "./NavBase.txt";
import {  classNamesFunction } from "@fluentui/react";
import { NavLink } from "./NavLink";
import { getTheme, mergeStyleSets } from "@fluentui/react";

const getClassNames = classNamesFunction();

export const SlimNav : React.FunctionComponent<any> = (props)=>  {
  // store the previous floating nav shown to close when the current floating nav shows up.
  const [isLinkExpandStateChanged,setLinkExpandStateChanged] = useState(false);
  const [selectedKey,setSelectedKey] = useState(props.initialSelectedKey || props.selectedKey);

  let _prevFloatingNav : any;

  let _hasAtleastOneHiddenLink = false;

  const { groups } = props;

  if (!groups?.length) {
    return null;
  }

  const getPreferredSelectedKey = () => {
    let selectedKey = "";
    // if caller passes in selectedKey, use it as first choice or use current state.selectedKey
    if (props.selectedKey) {
      selectedKey = props.selectedKey;
    } else if (selectedKey) {
      selectedKey = selectedKey;
    }
    return selectedKey;
  }

  const isChildLinkSelected = (link) => {
    const selectedKey = getPreferredSelectedKey();
    if (!selectedKey || !link?.links?.length) {
      return false;
    }
    return link.links.some(childLink => {
      return !!childLink && childLink.key === selectedKey;
    });
  }

  // given a link and an optional includeChildren parameter, find if the link or any of the children is selected
  const isLinkSelected = (link, includeChildren) =>{
    const selectedKey = getPreferredSelectedKey();
    if (!selectedKey || !link) {
      return false;
    }
    // check if the link or any of the child link is selected
    return (
      link.key === selectedKey ||
      (includeChildren && isChildLinkSelected(link))
    );
  }

  const getLinkText = (link, showMore) => {
    if (!link) {
      return undefined;
    }
    if (link.isShowMoreLink && !showMore && link.alternateText) {
      // if the link is show more/less link, based on the showMore state; return "Show more" localized text
      return link.alternateText;
    }
    return link.name;
  }

  // find if atleast one child link is visible using isHidden property
  // showMore flag will overwrite isHidden property
  const hasAtleastOneVisibleLink = (links, showMore) =>{
    return links?.length && (
      links.some(link => {
        return !link.isHidden;
      }) || !!showMore
    );
  }



  

  const _onLinkClicked = (link, ev) => {
    // set selected node
    setSelectedKey(link.key)

    const hasChildren = link.links?.length;

    // if there is no children and onClick handler is defined, call it
    if (!hasChildren && link.onClick) {
      // if there is a onClick defined, call it
      link.onClick(ev, link);
    }

    // prevent url action on anchor tag if the node has a children or if the onClick handler is defined
    if (hasChildren || link.onClick) {
      ev.preventDefault();
    }
    ev.stopPropagation();
  }

  const _onLinkMouseEnterOrLeave = (link, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    link.scrollTop = false;
    setLinkExpandStateChanged(true);
  }

  const _getFloatingNav = (parentElement) => {
    return parentElement && parentElement.querySelector("[data-floating-nav]");
  }

  const _onKeyDown = (link, ev) => {
    const nativeEvent = ev;
    if (nativeEvent.keyCode !== 13) {
      // accept only enter key to open the floating nav from slim nav
      return;
    }

    const a = nativeEvent.target;
    const li = a.parentElement;
    const currentFloatingNav = _getFloatingNav(li);

    if (!currentFloatingNav) {
      return;
    }

    if (_prevFloatingNav === currentFloatingNav) {
      // toggle the floating nav
      if (currentFloatingNav?.style?.display === "block") {
        currentFloatingNav.removeAttribute("style");
      } else {
        currentFloatingNav.setAttribute("style", "display: block");
      }
    } else {
      // prev and current floating navs are different
      // close the previous if there is one
      if (_prevFloatingNav) {
        _prevFloatingNav.removeAttribute("style");
      }

      // open the current one
      currentFloatingNav.setAttribute("style", "display: block");

      // store the current as prev
      _prevFloatingNav = currentFloatingNav;
    }
  }

  const _renderCompositeLink = (link, linkIndex, nestingLevel) => {
    const { styles, showMore, onShowMoreLinkClicked } = props;

    if (!link) {
      return null;
    }

    let rightIconName = undefined;
    if (link.url && link.target === "_blank") {
      // for external links, show an icon
      rightIconName = "OpenInNewWindow";
    }

    const isSelected =
      nestingLevel > 0 &&
      isLinkSelected(link, false /* includeChildren */);
    const classNames = mergeStyleSets(getStyles({...styles,isSelected,nestingLevel,theme: getTheme()}));
 
    const linkText = getLinkText(link, showMore);
    const onClickHandler =
      link.isShowMoreLink && onShowMoreLinkClicked
        ? onShowMoreLinkClicked
        : _onLinkClicked.bind(this, link);

    return (
      <NavLink
        id={link.key}
        content={linkText}
        href={link.url}
        target={link.target}
        dataValue={link.key}
        ariaLabel={linkText}
        role="menu"
        onClick={onClickHandler}
        rootClassName={classNames.navFloatingItemRoot}
        rightIconName={rightIconName}
        textClassName={classNames.navItemNameColumn}
        iconClassName={classNames.navItemIconColumn}
        barClassName={classNames.navItemBarMarker}
        focusedStyle={classNames.focusedStyle}
      />
    );
  }

  const _renderFloatingLink = (link, linkIndex, nestingLevel) => {
    const { showMore } = props;

    if (!link) {
      return null;
    }

    const linkText = getLinkText(link, showMore);

    return (
      <li key={link.key || linkIndex} title={linkText}>
        {_renderCompositeLink(link, linkIndex, nestingLevel)}
        {// show child links
        // 1. only for the first level
        nestingLevel === 0 ? (
          <div>{_renderFloatingLinks(link.links, ++nestingLevel)}</div>
        ) : null}
      </li>
    );
  }

  const _renderFloatingLinks = (links, nestingLevel) => {
    if (!links?.length) {
      return null;
    }

    return (
      <ul>
        {links.map((link, linkIndex) => {
          return _renderFloatingLink(link, linkIndex, nestingLevel);
        })}
      </ul>
    );
  }

  const _renderFloatingNav = (link, _linkIndex) => {
    const { styles } = props;

    if (!link) {
      return null;
    }

    const hasChildren = link.links?.length;
    const classNames = mergeStyleSets(getStyles({...styles,hasChildren,scrollTop: link.scrollTop,theme: getTheme()}));
    return (
      <div className={classNames.navFloatingRoot} data-floating-nav>
        {_renderFloatingLinks([link], 0 /* nestingLevel */)}
      </div>
    );
  }

  const _renderLink = (link, linkIndex, _nestingLevel) => {
    const { styles, showMore, onShowMoreLinkClicked } = props;

    if (!link) {
      return null;
    }

    const isSelected = isLinkSelected(link, true /* includeChildren */);
    const hasChildren = link.links?.length;
    const classNames = mergeStyleSets(getStyles({...styles,hasChildren,isSelected,theme: getTheme()}));
    const linkText = getLinkText(link, showMore);
    const onClickHandler =
      link.isShowMoreLink && onShowMoreLinkClicked
        ? onShowMoreLinkClicked
        : _onLinkClicked.bind(this, link);

    return (
      <li
        key={link.key || linkIndex}
        onMouseEnter={_onLinkMouseEnterOrLeave.bind(this, link)}
        onMouseLeave={_onLinkMouseEnterOrLeave.bind(this, link)}
        onKeyDown={_onKeyDown.bind(this, link)}
        title={linkText}
        className={classNames.navSlimItemRoot}
      >
        <NavLink
          id={link.key}
          href={link.url}
          target={link.target}
          dataValue={link.key}
          ariaLabel={linkText}
          role="menu"
          onClick={onClickHandler}
          rootClassName={classNames.navItemRoot}
          leftIconName={link.icon}
          iconClassName={classNames.navItemIconColumn}
          barClassName={classNames.navItemBarMarker}
          focusedStyle={classNames.focusedStyle}
        />
        {_renderFloatingNav(link, linkIndex)}
      </li>
    );
  }

  const _renderLinks = (links, nestingLevel) => {
    const { showMore } = props;

    if (!links?.length) {
      return null;
    }

    return (
      <ul>
        {links.map((link, linkIndex) => {
          if (link.isHidden && !showMore) {
            // atleast one link is hidden
            _hasAtleastOneHiddenLink = true;

            // "Show more" overrides isHidden property
            return null;
          } else if (
            link.isShowMoreLink &&
            !_hasAtleastOneHiddenLink &&
            !showMore
          ) {
            // there is no hidden link, hide "Show more" link
            return null;
          } else {
            return _renderLink(link, linkIndex, nestingLevel);
          }
        })}
      </ul>
    );
  }

  const _renderGroup = (group, groupIndex) =>{
    const { styles } = props;

    if (!group?.links?.length) {
      return null;
    }
    const classNames = mergeStyleSets(getStyles({...styles,theme: getTheme()}));

    let isGroupHeaderVisible = false;

    // first group header is hidden by default, display group header for other groups only if there are visible links
    if (groupIndex > 0) {
      isGroupHeaderVisible = hasAtleastOneVisibleLink(
        group.links,
        props.showMore,
      );
    }

    return (
      <div key={groupIndex}>
        {// do not render group header for the first group
        isGroupHeaderVisible ? (
          <div className={classNames.navGroupSeparatorRoot}>
            <div className={classNames.navGroupSeparatorHrLine} />
          </div>
        ) : null}
        {_renderLinks(group.links, 0 /* nestingLevel */)}
      </div>
    );
  }

  return (
    <>
      {groups.map((group, groupIndex) => {
        return _renderGroup(group, groupIndex);
      })}
    </>
  );

}
