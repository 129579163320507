import { AxiosError, AxiosInstance } from "axios";
import { IFailureResponse, ISuccessResponse, ParseAxiosError, ResponseType } from "util/Constant";

export interface ILocale {
    _id?: string;
    name: any;
    code: string;
    isActive?:boolean;
};

export class LocaleService {
    private httpClient : AxiosInstance;
    private path : string = "/locale";
    
    constructor(httpClient : AxiosInstance) {
      this.httpClient = httpClient;
    }
  
    public async lookup() : Promise<ISuccessResponse|IFailureResponse> {
      try{
          let {data} = await this.httpClient.get(`${this.path}/lookup`);
          data.type=ResponseType.SUCCESS;
          return data;
      }catch(error){
        return ParseAxiosError(error as AxiosError);
      }
    }

    public async list(searchParams:any) : Promise<ISuccessResponse|IFailureResponse> {
      try{
        let {data} = await this.httpClient.post(`${this.path}/search`,{search:searchParams});
        data.type=ResponseType.SUCCESS;
          return data;
      }catch(error){
        return ParseAxiosError(error as AxiosError);
      }
    }

  public async save(country:ILocale) : Promise<ISuccessResponse|IFailureResponse> {
    try{
      let {data} = await this.httpClient.post(`${this.path}`,country);
      data.type=ResponseType.SUCCESS;
          return data;
      }catch(error){
        return ParseAxiosError(error as AxiosError);
      }
   }

  public async update(country:ILocale) : Promise<ISuccessResponse|IFailureResponse> {
    try{
      let {data} = await this.httpClient.put(`${this.path}/${country._id}`,country);
      data.type=ResponseType.SUCCESS;
          return data;
      }catch(error){
        return ParseAxiosError(error as AxiosError);
      }
  }

  public async delete(locale_id:string) : Promise<ISuccessResponse|IFailureResponse> {
    try{
      let {data} = await this.httpClient.delete(`${this.path}/${locale_id}`);
      data.type=ResponseType.SUCCESS;
          return data;
      }catch(error){
        return ParseAxiosError(error as AxiosError);
      }
  }
}