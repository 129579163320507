import React from 'react';
import { Switch, Route ,useHistory,useLocation} from 'react-router-dom';
import {flattenDeep, isArray, isNil} from 'lodash-es';
import {AuthorizedRoute} from './AuthorizedRoute';
import RouteIndex from './RouteIndex';
import ComingSoon from 'pages/ComingSoon';
import NoMatch from 'pages/NoMatch';

export const RenderRoutes : React.FunctionComponent <{routeConfig:any}> = ({routeConfig}) => {

  const mapConfigToRoutes = (route) => {
    const isGroup = isArray(route.children);
    const PageComponent = isNil(route.component)
      ? isGroup
        ? RouteIndex
        : ComingSoon
      : route.component;
  
    const routeComponent = (
      <AuthorizedRoute
        key={route.uniqueKey}
        path={route.path}
        exact={route.exact || isArray(route.children)}
        strict={route.strict}
        isPublic={route.isPublic}
      >
        <PageComponent route={route} />
      </AuthorizedRoute>
    );
  
    const childComponents = isGroup ? route.children.map(mapConfigToRoutes) : [];
    return [routeComponent, ...childComponents];
  }
  const routeComponents = mapConfigToRoutes(routeConfig);
  const flatRouteComponents = flattenDeep(routeComponents);
  return (
      <Switch>
        {flatRouteComponents}
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
  );
}
