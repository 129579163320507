import { AxiosError } from "axios";
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import intersection from 'lodash-es/intersection';

export const BASE_DOMAIN_URL = process.env.REACT_APP_BASE_DOMAIN_URL;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export enum ResponseType {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

export enum EditMode {
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    VIEW="VIEW"
}

export enum Action {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE="DELETE"
}

export enum CssUnit {
  PIXEL = "px",
  PERCENT = "%",
  EM = "em",
  REM = "rem"
}

export enum AdminRoles {
  ROOT_ADMIN = "RootAdmin",
  ADMIN = "Admin"
}

export enum UserRoles {
  'CATALOGUE_READ' =  'CATALOGUE_READ',
  'CATALOGUE_WRITE' =  'CATALOGUE_WRITE',
  'CATALOGUE_DELETE' =  'CATALOGUE_DELETE',
  'CATALOGUE_PUBLISH' =  'CATALOGUE_PUBLISH',
  'NAVIGATION_READ' =  'NAVIGATION_READ',
  'NAVIGATION_WRITE' =  'NAVIGATION_WRITE',
  'NAVIGATION_DELETE' =  'NAVIGATION_DELETE',
  'NAVIGATION_PUBLISH' =  'NAVIGATION_PUBLISH',
  'BRAND_READ' =  'BRAND_READ',
  'BRAND_WRITE' =  'BRAND_WRITE',
  'BRAND_DELETE' =  'BRAND_DELETE',
  'MANUFACTURER_READ' =  'MANUFACTURER_READ',
  'MANUFACTURER_WRITE' =  'MANUFACTURER_WRITE',
  'MANUFACTURER_DELETE' =  'MANUFACTURER_DELETE',
  'PAGE_READ' =  'PAGE_READ',
  'PAGE_WRITE' =  'PAGE_WRITE',
  'PAGE_DELETE' =  'PAGE_DELETE',
  'PAGE_PUBLISH' =  'PAGE_PUBLISH',
  'PRODUCTPAGE_READ' =  'PRODUCTPAGE_READ',
  'PRODUCTPAGE_WRITE' =  'PRODUCTPAGE_WRITE',
  'PRODUCTPAGE_DELETE' =  'PRODUCTPAGE_DELETE',
  'PRODUCTPAGE_PUBLISH' =  'PRODUCTPAGE_PUBLISH',
  'ASSET_READ' =  'ASSET_READ',
  'ASSET_WRITE' =  'ASSET_WRITE', 
  'ASSET_DELETE' =  'ASSET_DELETE',
  'IMAGESHAPE_READ' =  'IMAGESHAPE_READ',
  'IMAGESHAPE_WRITE' =  'IMAGESHAPE_WRITE',
  'IMAGESHAPE_DELETE' =  'IMAGESHAPE_DELETE',
  'FAMILY_READ' =  'FAMILY_READ',
  'FAMILY_WRITE' =  'FAMILY_WRITE',
  'FAMILY_DELETE' =  'FAMILY_DELETE',
  'FAMILY_PUBLISH' =  'FAMILY_PUBLISH',
  'GROUP_READ'  = 'GROUP_READ',
  'GROUP_WRITE' = 'GROUP_WRITE',
  'GROUP_DELETE' = 'GROUP_DELETE',
  'GROUP_PUBLISH' = 'GROUP_PUBLISH',
  'PRICE_READ' = 'PRICE_READ',
  'PRICE_WRITE' = 'PRICE_WRITE',
  'PRICE_DELETE' = 'PRICE_DELETE',
  'PRICE_PUBLISH' = 'PRICE_PUBLISH',
  'PRODUCT_ADDON_READ' =  'PRODUCT_ADDON_READ',
  'PRODUCT_ADDON_WRITE' =  'PRODUCT_ADDON_WRITE',
  'PRODUCT_ADDON_DELETE' =  'PRODUCT_ADDON_DELETE',
  'PRODUCT_ADDON_PUBLISH' =  'PRODUCT_ADDON_PUBLISH',
  'PRODUCT_MODEL_READ' =  'PRODUCT_MODEL_READ',
  'PRODUCT_MODEL_WRITE' =  'PRODUCT_MODEL_WRITE',
  'PRODUCT_MODEL_DELETE' =  'PRODUCT_MODEL_DELETE',
  'PRODUCT_MODEL_PUBLISH' =  'PRODUCT_MODEL_PUBLISH',
  'PRODUCT_VARIANT_READ' =  'PRODUCT_VARIANT_READ',
  'PRODUCT_VARIANT_WRITE' =  'PRODUCT_VARIANT_WRITE',
  'PRODUCT_VARIANT_DELETE' =  'PRODUCT_VARIANT_DELETE',
  'PRODUCT_VARIANT_PUBLISH' =  'PRODUCT_VARIANT_PUBLISH',
  'PRODUCT_LISTING_READ' = 'PRODUCT_LISTING_READ',
  'PRODUCT_LISTING_WRITE' = 'PRODUCT_LISTING_WRITE',
  'PRODUCT_LISTING_DELETE' = 'PRODUCT_LISTING_DELETE',
  'PRODUCT_LISTING_PUBLISH' = 'PRODUCT_LISTING_PUBLISH',
  'ATTRIBUTE_SHAPE_READ' =  'ATTRIBUTE_SHAPE_READ',
  'ATTRIBUTE_SHAPE_WRITE' =  'ATTRIBUTE_SHAPE_WRITE',
  'ATTRIBUTE_SHAPE_DELETE' =  'ATTRIBUTE_SHAPE_DELETE',
  'ATTRIBUTE_VARIANT_SHAPE_READ' =  'ATTRIBUTE_VARIANT_SHAPE_READ',
  'ATTRIBUTE_VARIANT_SHAPE_WRITE' =  'ATTRIBUTE_VARIANT_SHAPE_WRITE',
  'ATTRIBUTE_VARIANT_SHAPE_DELETE' =  'ATTRIBUTE_VARIANT_SHAPE_DELETE',
  'FACETS_READ' =  'FACETS_READ',
  'FACETS_WRITE' =  'FACETS_WRITE',
  'FACETS_DELETE' =  'FACETS_DELETE',
  'TAGS_READ' =  'TAGS_READ',
  'TAGS_WRITE' =  'TAGS_WRITE',
  'TAGS_DELETE' =  'TAGS_DELETE',
  'TAGS_PUBLISH' =  'TAGS_PUBLISH',
  'PUBLISH_OBJECTS_READ' = 'PUBLISH_OBJECTS_READ',
  'PUBLISH_OBJECTS_DELETE' = 'PUBLISH_OBJECTS_DELETE', 
  'PUBLISH_OBJECTS_UNPUBLISH' = 'PUBLISH_OBJECTS_UNPUBLISH'
}

export const isVisible = (roles:Array<string>,allowedRoles:{admin ?: Array<string>,user ?:Array<string>}) : boolean => {
  if(!isEmpty(roles) && !isEmpty(allowedRoles)){
    let adminRoles = allowedRoles?.admin  ? allowedRoles.admin : [];
    let userRoles = allowedRoles.user;
    if(!isEmpty(adminRoles) && intersection(roles,adminRoles).length > 0){
      return true;
    }
    else if(!isEmpty(userRoles) && intersection(roles,userRoles).length > 0){
      return true;
    }
    else
      return false;
  }else
    return false;
}

export const modules={
  'CATALOGUE':{name:"Catalogue","accessType" : "user",'path':'category',actions : {
    'CATALOGUE_READ' : ['CATALOGUE_READ'],
    'CATALOGUE_WRITE' : ['CATALOGUE_READ'],
    'CATALOGUE_DELETE' : ['CATALOGUE_READ','CATALOGUE_WRITE'],
    'CATALOGUE_PUBLISH' : ['CATALOGUE_READ','CATALOGUE_WRITE','CATALOGUE_DELETE'],
  }},
  'NAVIGATION':{name:"Navigation","accessType" : "user",'path':'navigation',actions : {
    'NAVIGATION_READ' : ['NAVIGATION_READ'],
    'NAVIGATION_WRITE' : ['NAVIGATION_READ'],
    'NAVIGATION_DELETE' : ['NAVIGATION_READ','NAVIGATION_WRITE'],
    'NAVIGATION_PUBLISH' : ['NAVIGATION_READ','NAVIGATION_WRITE','NAVIGATION_DELETE'],
  }},
  'BRAND':{name:"Brand","accessType" : "user",'path':'brand',actions : {
    'BRAND_READ' : ['BRAND_READ'],
    'BRAND_WRITE' : ['BRAND_READ'],
    'BRAND_DELETE' : ['BRAND_READ','BRAND_WRITE']
  }},
  'MANUFACTURER':{name:"Manufacturer","accessType" : "user",'path':'manufacturer',actions : {
    'MANUFACTURER_READ' : ['MANUFACTURER_READ'],
    'MANUFACTURER_WRITE' : ['MANUFACTURER_READ'],
    'MANUFACTURER_DELETE' : ['MANUFACTURER_READ','MANUFACTURER_WRITE']
  }},
  'PAGE':{name:"Page","accessType" : "user",'path':'page',actions : {
    'PAGE_READ' : ['PAGE_READ'],
    'PAGE_WRITE' : ['PAGE_READ'],
    'PAGE_DELETE' : ['PAGE_READ','PAGE_WRITE'],
    'PAGE_PUBLISH' : ['PAGE_READ','PAGE_WRITE','PAGE_DELETE'],
  }},
  'PRODUCTPAGE':{name:"Product PageElement","accessType" : "user",'path':'productpageelement',actions : {
    'PRODUCTPAGE_READ' : ['PRODUCTPAGE_READ'],
    'PRODUCTPAGE_WRITE' : ['PRODUCTPAGE_READ'],
    'PRODUCTPAGE_DELETE' : ['PRODUCTPAGE_READ','PRODUCTPAGE_WRITE'],
    'PRODUCTPAGE_PUBLISH' : ['PRODUCTPAGE_READ','PRODUCTPAGE_WRITE','PRODUCTPAGE_DELETE'],
  }},
  'ASSET':{name:"Asset","accessType" : "user",'path':'gcpasset',actions : {
    'ASSET_READ' : ['ASSET_READ'],
    'ASSET_WRITE' : ['ASSET_READ'],
    'ASSET_DELETE' : ['ASSET_READ','ASSET_WRITE'],
  }},
  'IMAGESHAPE':{name:"Image Shape","accessType" : "user",'path':'imageShape',actions : {
    'IMAGESHAPE_READ' : ['IMAGESHAPE_READ'],
    'IMAGESHAPE_WRITE' : ['IMAGESHAPE_READ'],
    'IMAGESHAPE_DELETE' : ['IMAGESHAPE_READ','IMAGESHAPE_WRITE'],
  }},
  'FAMILY':{name:"Family","accessType" : "user",'path':'family',actions : {
    'FAMILY_READ' : ['FAMILY_READ'],
    'FAMILY_WRITE' : ['FAMILY_READ'],
    'FAMILY_DELETE' : ['FAMILY_READ','FAMILY_WRITE'],
    'FAMILY_PUBLISH' : ['FAMILY_READ','FAMILY_WRITE','FAMILY_DELETE'],
  }},
  'GROUP':{name:"Group","accessType" : "user",'path':'group',actions : {
    'GROUP_READ' : ['GROUP_READ'],
    'GROUP_WRITE' : ['GROUP_READ'],
    'GROUP_DELETE' : ['GROUP_READ','GROUP_WRITE'],
    'GROUP_PUBLISH' : ['GROUP_READ','GROUP_WRITE','GROUP_DELETE'],
  }},
  'PRICE':{name:"Pricing","accessType" : "user",'path':'pricing',actions : {
    'PRICE_READ' : ['PRICE_READ'],
    'PRICE_WRITE' : ['PRICE_READ'],
    'PRICE_DELETE' : ['PRICE_READ','PRICE_WRITE'],
    'PRICE_PUBLISH' : ['PRICE_READ','PRICE_WRITE','PRICE_DELETE'],
  }},
  'PRODUCT_ADDON':{name:"Product Addon","accessType" : "user",'path':'productAddOn',actions : {
    'PRODUCT_ADDON_READ' : ['PRODUCT_ADDON_READ'],
    'PRODUCT_ADDON_WRITE' : ['PRODUCT_ADDON_READ'],
    'PRODUCT_ADDON_DELETE' : ['PRODUCT_ADDON_READ','PRODUCT_ADDON_WRITE'],
    'PRODUCT_ADDON_PUBLISH' : ['PRODUCT_ADDON_READ','PRODUCT_ADDON_WRITE','PRODUCT_ADDON_DELETE'],
  }},
  'PRODUCT_MODEL':{name:"Product Model","accessType" : "user",'path':'productModel',actions : {
    'PRODUCT_MODEL_READ' : ['PRODUCT_MODEL_READ'],
    'PRODUCT_MODEL_WRITE' : ['PRODUCT_MODEL_READ'],
    'PRODUCT_MODEL_DELETE' : ['PRODUCT_MODEL_READ','PRODUCT_MODEL_WRITE'],
    'PRODUCT_MODEL_PUBLISH' : ['PRODUCT_MODEL_READ','PRODUCT_MODEL_WRITE','PRODUCT_MODEL_DELETE'],
  }},
  'PRODUCT_VARIANT':{name:"Product Variant","accessType" : "user",'path':'productVariant',actions : {
    'PRODUCT_VARIANT_READ' : ['PRODUCT_VARIANT_READ'],
    'PRODUCT_VARIANT_WRITE' : ['PRODUCT_VARIANT_READ'],
    'PRODUCT_VARIANT_DELETE' : ['PRODUCT_VARIANT_READ','PRODUCT_VARIANT_WRITE'],
    'PRODUCT_VARIANT_PUBLISH' : ['PRODUCT_VARIANT_READ','PRODUCT_VARIANT_WRITE','PRODUCT_VARIANT_DELETE'],
  }},
  'PRODUCT_LISTING':{name:"Product Listing","accessType" : "user",'path':'productListing',actions : {
    'PRODUCT_LISTING_READ' : ['PRODUCT_LISTING_READ'],
    'PRODUCT_LISTING_WRITE' : ['PRODUCT_LISTING_READ'],
    'PRODUCT_LISTING_DELETE' : ['PRODUCT_LISTING_READ','PRODUCT_LISTING_WRITE'],
    'PRODUCT_LISTING_PUBLISH' : ['PRODUCT_LISTING_READ','PRODUCT_LISTING_WRITE','PRODUCT_LISTING_DELETE'],
  }},
  'ATTRIBUTE_SHAPE':{name:"Attribute Shape","accessType" : "user",'path':'attributeShape',actions : {
    'ATTRIBUTE_SHAPE_READ' : ['ATTRIBUTE_SHAPE_READ'],
    'ATTRIBUTE_SHAPE_WRITE' : ['ATTRIBUTE_SHAPE_READ'],
    'ATTRIBUTE_SHAPE_DELETE' : ['ATTRIBUTE_SHAPE_READ','ATTRIBUTE_SHAPE_WRITE'],
  }},
  'ATTRIBUTE_VARIANT_SHAPE':{name:"Varaint Shape","accessType" : "user",'path':'variantAttributeShape',actions : {
    'ATTRIBUTE_VARIANT_SHAPE_READ' : ['ATTRIBUTE_VARIANT_SHAPE_READ'],
    'ATTRIBUTE_VARIANT_SHAPE_WRITE' : ['ATTRIBUTE_VARIANT_SHAPE_READ'],
    'ATTRIBUTE_VARIANT_SHAPE_DELETE' : ['ATTRIBUTE_VARIANT_SHAPE_READ','ATTRIBUTE_VARIANT_SHAPE_WRITE']
  }},
  'FACETS':{name:"Facets","accessType" : "user",'path':'facets',actions : {
    'FACETS_READ' : ['FACETS_READ'],
    'FACETS_WRITE' : ['FACETS_READ'],
    'FACETS_DELETE' : ['FACETS_READ','FACETS_WRITE']
  }},
  'TAGS':{name:"Tags","accessType" : "user",'path':'tags',actions : {
    'TAGS_READ' : ['TAGS_READ'],
    'TAGS_WRITE' : ['TAGS_READ'],
    'TAGS_DELETE' : ['TAGS_READ','TAGS_WRITE'],
    'TAGS_PUBLISH' : ['TAGS_READ','TAGS_WRITE','TAGS_DELETE'],
  }}
}

export interface ISuccessResponse  {
  type:ResponseType.SUCCESS;
  status:string;
  id?:string;
  msg:any;
}

export interface IFailureResponse {
  type:ResponseType.FAILED;
  status_code:number;
  error_code?:string;
  msg:string;
  errors?:any;
}

export interface ISearchParams  {
  search?:any;
  sort?:string;
  page:number;
  limit:number;
}

export type LookupOptions = {
  id?:string;
  value:string;
  label:any;
  image?:string;
}

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const isDev = () : boolean => development;


export const isValidUrl = urlString => {
  const urlRegex = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
  const result = urlString.match(urlRegex);
  return result !== null;
};


export interface ClassName {
  [key: string]: boolean;
}

export  const rLocaleText = (obj,viewLocale)=>{
  //Priority -> ViewLocale -> all
  return isEmpty(obj?.[viewLocale]) ? (isEmpty(obj?.["all"]) ? "NA" : obj?.["all"]) : obj?.[viewLocale];
}


export function classNames(classes: ClassName): string {
  return Object.entries(classes)
    .filter(([, value]) => value)
    .map(([key]) => key)
    .join(' ');
}

export const moveArray = (arr, fromIndex, toIndex) => {
  if (toIndex === fromIndex || toIndex >= arr.length) return arr;

  const toMove = arr[fromIndex];
  const movedForward = fromIndex < toIndex;

  return arr.reduce((res, next, index) => {
    if (index === fromIndex) return res;
    if (index === toIndex) return res.concat(
      movedForward ? [next, toMove] : [toMove, next]
    );

    return res.concat(next);
  }, []);
};


export const ParseAxiosError = (err : AxiosError) : IFailureResponse => {
  if (err.isAxiosError && err.response) { 
     const status_code = err.response.status;
     const errData = err.response.data;
     return  {type:ResponseType.FAILED,status_code,msg:errData?.msg,error_code:errData?.error_code,errors :errData?.errors}
  }else{
    return  {type:ResponseType.FAILED,status_code : err?.response?.status,msg:err?.message}
  }
}


