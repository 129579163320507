import React from 'react';
import { useSessionStorage } from 'react-use';
import clone from 'lodash-es/clone';

interface AuthenticationProps {
  isAuthenticated: boolean,
  principal: any,
  settings:{
    default:{
    locale?:string
    }
  },
  isActionLoading:boolean,
  resetLocale:(locale:string)=>void;
  setActionLoading:(isLoading:boolean)=>void;
  login: (principal:any,settings:any) => void;
  logout: () => void;
};

const defaultValues = {
  isAuthenticated: false,
  principal: {},
  settings:{default:{
    locale:""
  }},
  isActionLoading:false,
  resetLocale: ()=>{},
  setActionLoading:()=>{},
  login: () => {},
  logout: () => {}
};

const AUTH_STORAGE_KEY = 'authentication';

const AuthenticationContext = React.createContext<Partial<AuthenticationProps>>(defaultValues);
export const AuthContextProvider = AuthenticationContext.Provider;

export const AuthenticationProvider = ({children}) => {
    
  const [authentication, setAuthentication] = useSessionStorage(
    AUTH_STORAGE_KEY,
    defaultValues
  );
  
  const login = async (principal,settings) => await setAuthentication({ ...authentication,isAuthenticated : true, principal : principal,settings:settings});

  const logout = () => {setAuthentication(defaultValues);}

  const resetLocale = async(locale)=>{
    let updValues =  clone(authentication);
    updValues.settings.default.locale = locale;
    await setAuthentication(updValues);
  } 

  const setActionLoading = async(isLoading)=>{
    let updValues =  clone(authentication);
    updValues.isActionLoading = isLoading;
    await setAuthentication(updValues);
  }
  
  const values = { ...authentication,resetLocale,setActionLoading, login, logout };
  return (
    <AuthContextProvider value={values}>
      {children}
    </AuthContextProvider>
  );
}

export function useAuthentication() {
  return React.useContext(AuthenticationContext);
}
