import React from 'react';
import { Stack, mergeStyleSets,getTheme  } from '@fluentui/react';
import { UserMenu } from './UserMenu';

export const TopMenu : React.FunctionComponent<any> = () =>{
  const theme = getTheme();
  const classNames = mergeStyleSets({
    root: {
      borderBottomStyle: 'solid',
      borderBottomColor: theme.semanticColors.bodyFrameDivider,
      borderBottomWidth: 1,
      padding: theme.spacing.s1,
      height: 48
    }
  });
  return (
    <Stack
      horizontal
      horizontalAlign="end"
      className={classNames.root}
      tokens={{ childrenGap: '1em' }}>
      <UserMenu />
    </Stack>
  );
}
