import { IAdminState } from "./IAdminState";

export const SET_LOGOUT_SESSION = 'SET_LOGOUT_SESSION';
export const SET_LOGIN_SESSION = 'SET_LOGIN_SESSION';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_CHANNEL = 'SET_CHANNEL';

interface SetLoginSessionAction {
    type: typeof SET_LOGIN_SESSION
    payload: any
}

interface SetLogoutSessionAction {
    type: typeof SET_LOGOUT_SESSION
}

interface SetCountryAction {
    type: typeof SET_COUNTRY
    payload:string
}

interface SetLocaleAction {
    type: typeof SET_LOCALE
    payload:string
}

interface SetChannelAction {
    type: typeof SET_CHANNEL
    payload:string
}
  
export type AdminActionTypes = SetLoginSessionAction | SetLogoutSessionAction | SetCountryAction | SetLocaleAction | SetChannelAction