import {IAdminState} from './IAdminState';

const InitialAdminState: IAdminState = {
    loggedIn: false,
    session: {},
    user: {},
    country:'',
    locale:'',
    channel:''
}

export default InitialAdminState;