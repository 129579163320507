import { AdminActionTypes, SET_CHANNEL, SET_COUNTRY, SET_LOCALE, SET_LOGIN_SESSION, SET_LOGOUT_SESSION } from './ActionTypes';
import { IAdminState } from './IAdminState';
import InitialAdminState from './InitialAdminState';

export function AdminReducer(state = InitialAdminState,action: AdminActionTypes): IAdminState {
  switch (action.type) {
    case SET_LOGIN_SESSION: 
      return {
        ...state,
        ...action.payload
      }
    
    case SET_LOGOUT_SESSION: 
        return {
          ...state,
          user : {},
          session : {},
          loggedIn : false,
          country:'',
          locale:'',
          channel:''
        }
    case SET_COUNTRY: 
        return {
          ...state,
          country : action.payload
        }
    case SET_LOCALE: 
        return {
          ...state,
          locale : action.payload
        }
    case SET_CHANNEL: 
        return {
          ...state,
          channel : action.payload
        }
    default:
      return state
  }
}