import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthentication } from '../util/authentication';

type AuthorisedRouteProps = {
  id ?: string,
  path : string,
  exact : boolean,
  strict : boolean,
  isPublic : boolean
}

export const AuthorizedRoute : React.FunctionComponent<AuthorisedRouteProps> = ({
  id,
  path,
  exact,
  strict,
  isPublic,
  children,
  ...rest
})=>{
  const { isAuthenticated } = useAuthentication();
  var authorized = isPublic || isAuthenticated;
  //console.log("Authorised Route -> Authenticated user : ",user);

  return (
    <Route
      {...rest}
      key={id}
      path={path}
      exact={exact}
      strict={strict}
      render={({ location }) =>
        authorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
