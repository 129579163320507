import React from 'react';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import intersection from 'lodash-es/intersection';
import {useHistory} from 'react-router-dom';
import {NavToggler} from './Nav';
import routeConfig from '../../routeConfig';
import useRoutePath from '../../route/useRoutePath';
import { useAuthentication } from 'util/authentication';

function isVisible(route,allowedRoles) {
  let isHidden = (route.isHidden === undefined) ? false : route.isHidden;
  //console.log("Route : ",route.name+" - "+isHidden);
  if(!isHidden && !isEmpty(route.roles) && !isEmpty(allowedRoles)){
    let adminRoles = allowedRoles?.admin  ? allowedRoles.admin : [];
    let userRoles = allowedRoles.user;
    //console.log("Allowed Roles : ",route.roles);
    if(!isEmpty(adminRoles) && intersection(route.roles,adminRoles).length > 0){
      //console.log("Allowed Admin Roles : ",route.roles);
      return true;
    }
    else if(!isEmpty(userRoles) && intersection(route.roles,userRoles).length > 0){
      //console.log("Allowed User Roles : ",route.roles);
      return true;
    }
    else
      return false;
  }
  return !isHidden;
}

export function Sidebar() {
  const history = useHistory();
  const {current, paths} = useRoutePath();
  const {principal} = useAuthentication();

  const homeLink = mapRouteToNavLink(routeConfig, false);
  const topPageLinks = routeConfig.children
    .filter(route => isVisible(route,principal.permissions) && !isArray(route.children))
    .map(route => mapRouteToNavLink(route, false));

  const groupLinks = routeConfig.children
    .filter(hasChildren)
    .map(route => ({
      name: route.name,
      groupType: 'MenuGroup',
      links: route.children
        .filter(route=>isVisible(route,principal.permissions))
        .map(child => mapRouteToNavLink(child, true))
    }));

  const navLinkGroups = [
    {
      links: [
        {
          key: 'Collapse',
          name: 'Collapsed',
          alternateText: 'Expanded',
          icon: 'GlobalNavButton',
          title: 'Collapse'
        }
      ],
      groupType: 'ToggleGroup'
    },
    {
      links: [homeLink, ...topPageLinks],
      groupType: 'MenuGroup'
    },
    ...groupLinks
  ];

  return (
    <NavToggler
      groups={navLinkGroups}
      selectedKey={current?.uniqueKey}
    />
  );


  function mapRouteToNavLink(route, deeply = true) {
    return {
      name: route.name,
      key: route.uniqueKey,
      alternateText: route.name,
      title: route.name,
      url: route.path,
      onClick: e => {
        e.preventDefault();
        history.push(route.path);
      },
      isExpanded:
        deeply &&
        hasChildren(route) &&
        paths.some(that => that.uniqueKey === route.uniqueKey),
      links:
        deeply &&
        hasChildren(route) &&
        route.children
          .filter(route=>isVisible(route,principal.permissions))
          .map(child => mapRouteToNavLink(child, deeply)),
      icon: route.icon
        ? route.icon
        : hasChildren(route) ? 'DocumentSet' : 'TextDocument'
    };
  }

  function hasChildren(route) {
    return route?.children?.filter(route=>isVisible(route,principal.permissions)).length;
  }
}
